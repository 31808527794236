import Confirmation from "@/pages/FineTuning/Steps/Confirmation";

const TRANSLATIONS = {
  common: {
    "workspaces-name": "Workspaces Name",
    error: "error",
    success: "success",
    user: "User",
    selection: "Model Selection",
    saving: "Saving...",
    save: "Save changes",
    previous: "Previous Page",
    next: "Next Page",
  },

  // Popup Confirmation
  deleteWorkspaceConfirmation:
    "Are you sure you want to delete {{name}}?\nAfter you do this it will be unavailable in this instance.\n\nThis action is irreversible.",
  deleteConfirmation:
    "Are you sure you want to delete ${user.username}?\nAfter you do this they will be logged out and unable to use this instance.\n\nThis action is irreversible.",
  suspendConfirmation:
    "Are you sure you want to suspend {{username}}?\nAfter you do this they will be logged out and unable to log back into this instance until unsuspended by an admin.",

  // Setting Sidebar menu items.
  settings: {
    title: "Instance Settings",
    system: "General Settings",
    invites: "Invites",
    users: "Users",
    workspaces: "Workspaces",
    "workspace-chats": "Workspace Chats",
    customization: "Customization",
    "api-keys": "Developer API",
    llm: "LLM",
    transcription: "Transcription",
    embedder: "Embedder",
    "text-splitting": "Text Splitter & Chunking",
    "vector-database": "Vector Database",
    embeds: "Chat Embed",
    "embed-chats": "Chat Embed History",
    security: "Security",
    "event-logs": "Event Logs",
    privacy: "Privacy & Data",
    "ai-providers": "AI Providers",
    "agent-skills": "Agent Skills",
    admin: "Admin",
    tools: "Tools",
    audio: "Audio Preference",
    "link-settings": "Settings",
    "default-settings": "Default Settings",
    "browser-extension": "Browser Extension",
  },

  // Qura buttons,
  qura: {
    "copy-to-cora": "Qura source check",
    "qura-status": "Qura button is ",
    "copy-option": "Copy option",
    "option-quest": "Question",
    "option-resp": "Response",
    "role-description": "Add a Qura button to prompt responses on Qura.law",
  },

  // Page Definitions
  login: {
    "multi-user": {
      welcome: "Welcome to",
      "placeholder-username": "Username",
      "placeholder-password": "Password",
      login: "Login",
      validating: "Validating...",
      "forgot-pass": "Forgot password",
      reset: "Reset",
    },
    "sign-in": {
      start: "Sign in to your account",
      end: "account.",
    },
    button: "login",
    password: {
      forgot: "FORGOT PASSWORD ? ",
      contact: "Please contact the system admin.",
    },
    publicMode: "Public Mode",
  },

  "new-workspace": {
    title: "New Workspace",
    placeholder: "My Workspace",
    "legal-areas": "Legal Areas",
    create: {
      title: "Create new workspace",
      description:
        "After creating this workspace only admins will be able to see it. You can add users after it has been created.",
      error: "Error: ",
      cancel: "Cancel",
      "create-workspace": "Create workspace",
    },
  },

  "workspace-chats": {
    welcome: "Welcome to your new workspace.",
    "desc-start": "To get started either",
    "desc-mid": "upload a document",
    "desc-or": "or",
    start: "To get started",
    "desc-end": "send a chat.",
    prompt: {
      send: "Send",
      "send-message": "Send message",
      placeholder: "Ask for legal information",
      slash: "View all available slash commands for chatting.",
      "change-size": "Change text size",
      reset: "Reset your chat",
      clear: "Clear your chat history and begin a new chat",
      "new-preset": "Add New Preset",
      command: "Command",
      description: "Description",
      save: "save",
      small: "Small",
      normal: "Normal",
      large: "Large",
      attach: "Attach a file to this chat",
      upgrade: "Upgrade your prompt",
      upgrading: "Upgrading your prompt",
      "original-prompt": "Original Prompt:",
      "upgraded-prompt": "Upgraded Prompt:",
      "edit-prompt": "You can edit the new prompt before submitting",
    },
  },

  header: {
    account: "Account",
    login: "Login",
    "sign-out": "Sign out",
  },

  workspace: {
    title: "Instance Workspaces",
    description:
      "These are all the workspaces that exist on this instance. Removing  workspace will delete all of its associated chats and settings.",
    "new-workspace": "New Workspace",
    name: "Name",
    link: "Link",
    users: "Users",
    type: "Type",
    "created-on": "Created On",
    save: "Save change",
    cancel: "Cancel",
    deleted: {
      title: "Workspace not found!",
      description: "It looks like a workspace by this name is not available.",
      homepage: "Go back to homepage",
    },
  },

  // Workspace Settings menu items
  "workspaces-settings": {
    general: "General Settings",
    chat: "Chat Settings",
    vector: "Vector Database",
    members: "Members",
    agent: "Agent Configuration",
    "general-settings": {
      "workspace-name": "Workspace Name",
      "desc-name": "This will only change the display name of your workspace.",
      "assistant-profile": "Assistant Profile Image",
      "assistant-image":
        "Customize the profile image of the assistant for this workspace.",
      "workspace-image": "Workspace Image",
      "remove-image": "Remove Workspace Image",
      delete: "Delete Workspace",
      deleting: "Deleting Workspace...",
      update: "Update workspace",
      updating: "Updating workspace...",
    },
    "chat-settings": {
      type: "Chat type",
      private: "Private",
      standard: "Standard",
      "private-desc-start": "will manually grant access to",
      "private-desc-mid": "only",
      "private-desc-end": "specific users.",
      "standard-desc-start": "will automatically grant access to",
      "standard-desc-mid": "all",
      "standard-desc-end": "new users.",
    },
    users: {
      manage: "Manage Users",
      "workspace-member": "No workspace members",
      username: "Username",
      role: "Role",
      date: "Date Added",
      users: "Users",
      search: "Search for a user",
      "no-user": "No users found",
      select: "Select All",
      unselect: "Unselect",
      save: "Save",
    },
    "linked-workspaces": {
      title: "Linked Workspaces",
      "linked-workspace": "No linked workspaces",
      manage: "Manage Workspaces",
      name: "Name",
      slug: "Slug",
      date: "Date Added",
      workspaces: "Workspaces",
      search: "Search for a workspace",
      "no-workspace": "No workspaces found",
      select: "Select All",
      unselect: "Unselect",
      save: "Save",
    },
  },

  // General Appearance
  general: {
    vector: {
      title: "Vector Count",
      description: "Total number of vectors in your vector database.",
      vectors: "Number of vectors",
    },
    names: {
      description: "This will only change the display name of your workspace.",
    },
    message: {
      title: "Suggested Chat Messages",
      description:
        "Customize the messages that will be suggested to your workspace users.",
      add: "Add new message",
      save: "Save Messages",
      heading: "Explain to me",
      body: "the benefits of platform",
      message: "Message",
      "new-heading": "Heading",
    },
    pfp: {
      title: "Assistant Profile Image",
      description:
        "Customize the profile image of the assistant for this workspace.",
      image: "Workspace Image",
      remove: "Remove Workspace Image",
    },
    delete: {
      delete: "Delete Workspace",
      deleting: "Deleting Workspace...",
      "confirm-start": "You are about to delete your entire",
      "confirm-end":
        "workspace. This will remove all vector embeddings in your vector database.\n\nThe original source files will remain untouched. This action is irreversible.",
    },
  },

  // Chat Settings
  chat: {
    llm: {
      title: "Workspace LLM Provider",
      description:
        "The specific LLM provider & model that will be used for this workspace. By default, it uses the system LLM provider and settings.",
      search: "Search all LLM providers",
    },
    model: {
      title: "Workspace Chat model",
      description:
        "The specific chat model that will be used for this workspace. If empty, will use the system LLM preference.",
      wait: "-- waiting for models --",
    },
    mode: {
      title: "Chat mode",
      chat: {
        title: "Chat",
        "desc-start": "will provide answers with the LLM's general knowledge",
        and: "and",
        "desc-end": "document context that is found.",
      },
      query: {
        title: "Query",
        "desc-start": "will provide answers",
        only: "only",
        "desc-end": "if document context is found.",
      },
    },
    history: {
      title: "Chat History",
      "desc-start":
        "The number of previous chats that will be included in the response's short-term memory.",
      recommend: "Recommend 20. ",
      "desc-end":
        "Anything more than 45 is likely to lead to continuous chat failures depending on message size.",
    },
    prompt: {
      title: "Prompt",
      description:
        "The prompt that will be used on this workspace. Define the context and instructions for the AI to generate a response. You should to provide a carefully crafted prompt so the AI can generate a relevant and accurate response.",
    },
    refusal: {
      title: "Query mode refusal response",
      "desc-start": "When in",
      query: "query",
      "desc-end":
        "mode, you may want to return a custom refusal response when no context is found.",
    },
    temperature: {
      title: "LLM Temperature",
      "desc-start":
        'This setting controls how "creative" your LLM responses will be.',
      "desc-end":
        "The higher the number the more creative. For some models this can lead to incoherent responses when set too high.",
      hint: "Most LLMs have various acceptable ranges of valid values. Consult your LLM provider for that information.",
    },
  },

  // Vector Database
  "vector-workspace": {
    identifier: "Vector database identifier",
    snippets: {
      title: "Max Context Snippets",
      description:
        "This setting controls the maximum amount of context snippets the will be sent to the LLM for per chat or query.",
      recommend: "Recommended: 4",
    },
    doc: {
      title: "Document similarity threshold",
      description:
        "The minimum similarity score required for a source to be considered related to the chat. The higher the number, the more similar the source must be to the chat.",
      zero: "No restriction",
      low: "Low (similarity score ≥ .25)",
      medium: "Medium (similarity score ≥ .50)",
      high: "High (similarity score ≥ .75)",
    },
    reset: {
      reset: "Reset Vector Database",
      resetting: "Clearing vectors...",
      confirm:
        "You are about to reset this workspace's vector database. This will remove all vector embeddings currently embedded.\n\nThe original source files will remain untouched. This action is irreversible.",
      error: "Workspace vector database could not be reset!",
      success: "Workspace vector database was reset!",
    },
  },

  // Agent Configuration
  agent: {
    "performance-warning":
      "Performance of LLMs that do not explicitly support tool-calling is highly dependent on the model's capabilities and accuracy. Some abilities may be limited or non-functional.",
    provider: {
      title: "Workspace Agent LLM Provider",
      description:
        "The specific LLM provider & model that will be used for this workspace's @agent agent.",
    },
    mode: {
      chat: {
        title: "Workspace Agent Chat model",
        description:
          "The specific chat model that will be used for this workspace's @agent agent.",
      },
      title: "Workspace Agent model",
      description:
        "The specific LLM model that will be used for this workspace's @agent agent.",
      wait: "-- waiting for models --",
    },

    skill: {
      title: "Default agent skills",
      description:
        "Improve the natural abilities of the default agent with these pre-built skills. This set up applies to all workspaces.",
      rag: {
        title: "RAG & long-term memory",
        description:
          'Allow the agent to leverage your local documents to answer a query or ask the agent to "remember" pieces of content for long-term memory retrieval.',
      },
      configure: {
        title: "Configure Agent Skills",
        description:
          "Customize and enhance the default agent's capabilities by enabling or disabling specific skills. These settings will be applied across all workspaces.",
      },
      view: {
        title: "View & summarize documents",
        description:
          "Allow the agent to list and summarize the content of workspace files currently embedded.",
      },
      scrape: {
        title: "Scrape websites",
        description:
          "Allow the agent to visit and scrape the content of websites.",
      },
      generate: {
        title: "Generate charts",
        description:
          "Enable the default agent to generate various types of charts from data provided or given in chat.",
      },
      save: {
        title: "Generate & save files to browser",
        description:
          "Enable the default agent to generate and write to files that save and can be downloaded in your browser.",
      },
      web: {
        title: "Live web search and browsing",
        "desc-start":
          "Enable your agent to search the web to answer your questions by connecting to a web-search (SERP) provider.",
        "desc-end":
          "Web search during agent sessions will not work until this is set up.",
      },
    },
  },

  // Workspace Chats
  recorded: {
    title: "Workspace Chats",
    description:
      "These are all the recorded chats and messages that have been sent by users ordered by their creation date.",
    export: "Export",
    table: {
      id: "Id",
      by: "Sent By",
      workspace: "Workspace",
      prompt: "Prompt",
      response: "Response",
      at: "Sent At",
    },
    "clear-chats": "Clear Chats",
  },

  // Appearance
  appearance: {
    title: "Appearance",
    description: "Customize the appearance settings of your platform.",
    logo: {
      title: "Customize logo",
      description: "Upload your custom logo for light mode.",
      add: "Add a custom logo",
      recommended: "Recommended size: 800 x 200",
      remove: "Remove",
      replace: "Replace",
    },
    logoDark: {
      title: "Customize dark mode logo",
      description: "Upload your custom logo for dark mode.",
      add: "Add a custom logo",
      recommended: "Recommended size: 800 x 200",
      remove: "Remove",
      replace: "Replace",
    },
    message: {
      title: "Customize Messages",
      description: "Customize the automatic messages displayed to your users.",
      new: "New",
      system: "system",
      user: "user",
      message: "message",
      assistant: "Chat Assistant",
      "double-click": "Double click to edit...",
      save: "Save Messages",
    },
    icons: {
      title: "Custom Footer Icons",
      description:
        "Customize the footer icons displayed on the bottom of the sidebar.",
      icon: "Icon",
      link: "Link",
    },
    display: {
      title: "Display Language",
      description: "Select the preferred language.",
    },
    color: {
      title: "Custom colors",
      "desc-start": "Customize the",
      "desc-mid": "*background-color, *primary-color",
      "desc-and": "and",
      "desc-end": "*text-color",
      red: "Red",
    },
    login: {
      title: "Custom Login Text",
      description: "Customize the paragraph text displayed on the login page.",
      save: "save",
      website: {
        title: "Customize the Application Website",
        description: "Customize the URL of your application's website.",
        toggle: "Display Website Link",
        save: "Save",
      },
      validation: {
        invalidURL: "Please enter a valid URL.",
      },
      showToast: {
        updatedWebsite: "Website settings updated successfully.",
      },
    },

    siteSettings: {
      title: "Custom Site Settings",
      description:
        "Change the content of the browser tab for customization and branding.",
      tabTitle: "Tab Title",
      tabDescription:
        "Set a custom tab title when the app is open in a browser.",
      tabIcon: "Tab Favicon",
      fabIconUrl: "Define a url to an image to use for your favicon",
    },
  },

  // API Keys
  api: {
    title: "API Keys",
    description:
      "API keys allow the holder to programmatically access and manage this instance.",
    link: "Read the API documentation",
    generate: "Generate New API Key",
    table: {
      key: "API Key",
      by: "Created By",
      created: "Created",
    },
    new: {
      title: "Create new API key",
      description:
        "Once created the API key can be used to programmatically access and configure this instance.",
      doc: "Read the API documentation",
      cancel: "Cancel",
      "create-api": "Create API key",
    },
  },

  llm: {
    title: "LLM Preference",
    description:
      "These are the credentials and settings for your preferred LLM chat & embedding provider. Its important these keys are current and correct or else the system will not function properly.",
    provider: "LLM Provider",
    "none-selected": "None selected",
    "select-llm": "You need to select an LLM",
    "search-llm": "Search all LLM providers",
  },

  "llm-provider": {
    openai: "The standard option for most non-commercial use.",
    azure: "The enterprise option of OpenAI hosted on Azure services.",
    anthropic: "A friendly AI Assistant hosted by Anthropic.",
    gemini: "Google's largest and most capable AI model",
    huggingface:
      "Access 150,000+ open-source LLMs and the world's AI community",
    ollama: "Run LLMs locally on your own machine.",
    lmstudio:
      "Discover, download, and run thousands of cutting edge LLMs in a few clicks.",
    localai: "Run LLMs locally on your own machine.",
    togetherai: "Run open source models from Together AI.",
    mistral: "Run open source models from Mistral AI.",
    perplexityai:
      "Run powerful and internet-connected models hosted by Perplexity AI.",
    openrouter: "A unified interface for LLMs.",
    groq: "The fastest LLM inferencing available for real-time AI applications.",
    koboldcpp: "Run local LLMs using koboldcpp.",
    oobabooga: "Run local LLMs using Oobabooga's Text Generation Web UI.",
    cohere: "Run Cohere's powerful Command models.",
    lite: "Run LiteLLM's OpenAI compatible proxy for various LLMs.",
    "generic-openai":
      "Connect to any OpenAi-compatible service via a custom configuration",
    native:
      "Use a downloaded custom Llama model for chatting on this instance.",
  },

  // audio preference
  audio: {
    title: "Speech-to-text Preference",
    provider: "Provider",
    "desc-speech":
      "Here you can specify what kind of text-to-speech and speech-to-text providers you would want to use in your platform experience. By default, we use the browser's built in support for these services, but you may want to use others.",
    "title-text": "Text-to-speech Preference",
    "desc-text":
      "Here you can specify what kind of text-to-speech providers you would want to use in your platform experience. By default, we use the browser's built in support for these services, but you may want to use others.",
    "desc-config": "There is no configuration needed for this provider.",
    "placeholder-stt": "Search speech to text providers",
    "placeholder-tts": "Search text to speech providers",
    "native-stt": "Uses your browser's built in STT service if supported.",
    "native-tts": "Uses your browser's built in TTS service if supported.",
    openai: "Use OpenAI's text to speech voices.",
    elevenlabs: "Use ElevenLabs's text to speech voices and technology.",
  },

  transcription: {
    title: "Transcription Model Preference",
    description:
      "These are the credentials and settings for your preferred transcription model provider. Its important these keys are current and correct or else media files and audio will not transcribe.",
    provider: "Transcription Provider",
    "warn-start":
      "Using the local whisper model on machines with limited RAM or CPU can stall the platform when processing media files.",
    "warn-recommend":
      "We recommend at least 2GB of RAM and upload files <10Mb.",
    "warn-end":
      "The built-in model will automatically download on the first use.",
    "search-audio": "Search audio transcription providers",
  },

  embedding: {
    title: "Embedding Preference",
    "desc-start":
      "When using an LLM that does not natively support an embedding engine - you may need to additionally specify credentials to for embedding text.",
    "desc-end":
      "Embedding is the process of turning text into vectors. These credentials are required to turn your files and prompts into a format which the platform can use to process.",
    provider: {
      title: "Embedding Provider",
      description:
        "There is no set up required when using the platform's native embedding engine.",
      "search-embed": "Search all embedding providers",
    },
  },

  text: {
    title: "Text splitting & Chunking Preferences",
    "desc-start":
      "Sometimes, you may want to change the default way that new documents are split and chunked before being inserted into your vector database.",
    "desc-end":
      "You should only modify this setting if you understand how text splitting works and it's side effects.",
    "warn-start": "Changes here will only apply to",
    "warn-center": "newly embedded documents",
    "warn-end": ", not existing documents.",
    size: {
      title: "Text Chunk Size",
      description:
        "This is the maximum length of characters that can be present in a single vector.",
      recommend: "Embed model maximum length is",
    },

    overlap: {
      title: "Text Chunk Overlap",
      description:
        "This is the maximum overlap of characters that occurs during chunking between two adjacent text chunks.",
    },
  },

  // Vector Database
  vector: {
    title: "Vector Database",
    description:
      "These are the credentials and settings for how your platform instance will function. It's important these keys are current and correct.",
    provider: {
      title: "Vector Database Provider",
      description: "There is no configuration needed for LanceDB.",
      "search-db": "Search all vector database providers",
    },
  },

  // Embeddable Chat Widgets
  embeddable: {
    title: "Embeddable Chat Widgets",
    description:
      "Embeddable chat widgets are public facing chat interfaces that are tied to a single workspace. These allow you to build workspaces that then you can publish to the world.",
    create: "Create embed",
    table: {
      workspace: "Workspace",
      chats: "Sent Chats",
      Active: "Active Domains",
    },
  },

  "embed-chats": {
    title: "Embed Chats",
    export: "Export",
    description:
      "These are all the recorded chats and messages from any embed that you have published.",
    table: {
      embed: "Embed",
      sender: "Sender",
      message: "Message",
      response: "Response",
      at: "Sent At",
    },
  },

  multi: {
    title: "Multi-User Mode",
    description:
      "Set up your instance to support your team by activating Multi-User Mode.",
    enable: {
      "is-enable": "Multi-User Mode is Enabled",
      enable: "Enable Multi-User Mode",
      description:
        "By default, you will be the only admin. As an admin you will need to create accounts for all new users or admins. Do not lose your password as only an Admin user can reset passwords.",
      username: "Admin account username",
      password: "Admin account password",
    },
    password: {
      title: "Password Protection",
      description:
        "Protect your instance with a password. If you forget this there is no recovery method so ensure you save this password.",
    },
    instance: {
      title: "Password Protect Instance",
      description:
        "By default, you will be the only admin. As an admin you will need to create accounts for all new users or admins. Do not lose your password as only an Admin user can reset passwords.",
      password: "Instance password",
    },
  },

  // Event Logs
  event: {
    title: "Event Logs",
    description:
      "View all actions and events happening on this instance for monitoring.",
    clear: "Clear Event Logs",
    table: {
      type: "Event Type",
      user: "User",
      occurred: "Occurred At",
    },
  },

  // Privacy & Data-Handling
  privacy: {
    title: "Privacy & Data-Handling",
    description:
      "This is your configuration for how connected third party providers and our platform handle your data.",
    llm: "LLM Selection",
    embedding: "Embedding Preference",
    vector: "Vector Database",
    anonymous: "Anonymous Telemetry Enabled",
    "desc-event": "All events do not record IP-address and contain",
    "desc-id": "no identifying",
    "desc-cont":
      "content, settings, chats, or other non-usage based information. To see the list of event tags collected you can look on",
    "desc-git": "Github here",
    "desc-end":
      "As an open-source project we respect your right to privacy. We are dedicated to building the best solution for integrating AI and documents privately and securely. If you do decide to turn off telemetry all we ask is to consider sending us feedback and thoughts so that we can continue to improve the platform for you",
  },
  "default-chat": {
    welcome: "Welcome to IST Legal.",
    "choose-legal": "Choose a legal area to the left.",
  },
  invites: {
    title: "Invitations",
    description:
      "Create invitation links for people in your organization to accept and sign up with. Invitations can only be used by a single user.",
    link: "Create Invite Link",
    status: "Status",
    accept: "Accepted By",
    "created-by": "Created By",
    created: "Created",
    new: {
      title: "Create new invite",
      "desc-start":
        "After creation you will be able to copy the invite and send it to a new user where they can create an account as the",
      "desc-mid": "default",
      "desc-end": "role and automatically be added to workspaces selected.",
      "auto-add": "Auto-add invitee to workspaces",
      "desc-add":
        "You can optionally automatically assign the user to the workspaces below by selecting them. By default, the user will not have any workspaces visible. You can assign workspaces later post-invite acceptance.",
      cancel: "Cancel",
      "create-invite": "Create Invite",
      error: "Error: ",
    },
  },

  "user-menu": {
    edit: "Edit Account",
    profile: "Profile Picture",
    size: "800 x 800",
    "remove-profile": "Remove Profile Picture",
    username: "Username",
    "username-placeholder": "User's username",
    "new-password": "New Password",
    "new-password-placeholder": "New password",
    cancel: "Cancel",
    update: "Update Account",
    language: "Preferred language",
  },

  sidebar: {
    thread: {
      "load-thread": "loading threads....",
      "starting-thread": "Starting Thread...",
      thread: "New Thread",
      delete: "Delete Selected",
      rename: "Rename",
      "delete-thread": "Delete Thread",
      deleted: "deleted",
      default: "default",
      "empty-thread": "Thread",
      "rename-message": "What would you like to rename this thread to?",
      "delete-message":
        "Are you sure you want to delete this thread? All of its chats will be deleted. You cannot undo this.",
    },
  },

  embeder: {
    allm: "Use the built-in embedding provider. Zero setup!",
    openai: "The standard option for most non-commercial use.",
    azure: "The enterprise option of OpenAI hosted on Azure services.",
    localai: "Run embedding models locally on your own machine.",
    ollama: "Run embedding models locally on your own machine.",
    lmstudio:
      "Discover, download, and run thousands of cutting edge LLMs in a few clicks.",
    cohere: "Run powerful embedding models from Cohere.",
    voyageai: "Run powerful embedding models from Voyage AI.",
  },

  vectordb: {
    lancedb:
      "100% local vector DB that runs on the same instance as the platform.",
    chroma:
      "Open source vector database you can host yourself or on the cloud.",
    pinecone: "100% cloud-based vector database for enterprise use cases.",
    zilliz:
      "Cloud hosted vector database built for enterprise with SOC 2 compliance.",
    qdrant: "Open source local and distributed cloud vector database.",
    weaviate: "Open source local and cloud hosted multi-modal vector database.",
    milvus: "Open-source, highly scalable, and blazing fast.",
    astra: "Vector Search for Real-world GenAI.",
  },

  system: {
    title: "System Preferences",
    "desc-start":
      "These are the overall settings and configurations of your instance.",
    user: "Users can delete workspaces",
    "desc-delete":
      "Allow non-admin users to delete workspaces that they are a part of. This would delete the workspace for everyone.",
    limit: "Limit messages per user per day",
    "desc-limit":
      "Restrict non-admin users to a number of successful queries or chats within a 24 hour window. Enable this to prevent users from running up OpenAI costs.",
    "limit-day": "Message limit per day",
    "custom-tab": "Customize Tab Name",
  },
  "user-setting": {
    description:
      "These are all the accounts which have an account on this instance. Removing an account will instantly remove their access to this instance.",
    "add-user": "Add User",
    username: "Username",
    role: "Role",
    "date-added": "Date Added",
  },

  support: {
    title: "Support Email",
    description:
      "Set the support email address that shows up in the user menu while logged into this instance.",
    clear: "Clear",
    save: "Save",
  },
  "public-mode": {
    enable: "Enable Public-User Mode",
    enabled: "Public-User Mode is Enabled",
  },
  button: {
    delete: "Delete",
    edit: "Edit",
    suspend: "Suspend",
    unsuspend: "Unsuspend",
    save: "Save",
    accept: "Accept",
    decline: "Decline",
  },

  "new-user": {
    title: "Add user to instance",
    username: "Username",
    "username-ph": "User's username",
    password: "Password",
    "password-ph": "User's initial password",
    role: "Role",
    default: "Default",
    manager: "Manager",
    admin: "Administrator",
    description:
      "After creating a user they will need to login with their initial login to get access.",
    cancel: "Cancel",
    "add-User": "Add User",
    error: "Error: ",
    "invalid-username":
      "Username can only include lowercase letters, numbers, underscores (_), dots (.), @ symbols, and hyphens (-).",
    permissions: {
      title: "Permissions",
      default: [
        "Can only send chats with workspaces they are added to by admin or managers.",
        "Cannot modify any settings at all.",
      ],
      manager: [
        "Can view, create, and delete any workspaces and modify workspace-specific settings.",
        "Can create, update and invite new users to the instance.",
        "Cannot modify LLM, vectorDB, embedding, or other connections.",
      ],
      admin: [
        "Highest user level privilege.",
        "Can see and do everything across the system.",
      ],
    },
  },

  "new-embed": {
    title: "Create new embed for workspace",
    error: "Error: ",
    "desc-start":
      "After creating an embed you will be provided a link that you can publish on your website with a simple",
    script: "script",
    tag: "tag.",
    cancel: "Cancel",
    "create-embed": "Create embed",
    workspace: "Workspace",
    "desc-workspace":
      "This is the workspace your chat window will be based on. All defaults will be inherited from the workspace unless overridden by this config.",
    "allowed-chat": "Allowed chat method",
    "desc-query":
      "Set how your chatbot should operate. Query means it will only respond if a document helps answer the query.",
    "desc-chat":
      "Chat opens the chat to even general questions and can answer totally unrelated queries to your workspace.",
    "desc-response": "Chat: Respond to all questions regardless of context",
    "query-response":
      "Query: Only respond to chats related to documents in workspace",
    restrict: "Restrict requests from domains",
    filter:
      "This filter will block any requests that come from a domain other than the list below.",
    "use-embed":
      "Leaving this empty means anyone can use your embed on any site.",
    "max-chats": "Max chats per day",
    "limit-chats":
      "Limit the amount of chats this embedded chat can process in a 24 hour period. Zero is unlimited.",
    "chats-session": "Max chats per session",
    "limit-chats-session":
      "Limit the amount of chats a session user can send with this embed in a 24 hour period. Zero is unlimited.",
    "enable-dynamic": "Enable dynamic model use",
    "llm-override":
      "Allow setting of the preferred LLM model to override the workspace default.",
    "llm-temp": "Enable dynamic LLM temperature",
    "desc-temp":
      "Allow setting of the LLM temperature to override the workspace default.",
    "prompt-override": "Enable Prompt Override",
    "desc-override":
      "Allow setting of the system prompt to override the workspace default.",
  },

  "show-toast": {
    "recovery-codes": "Recovery codes copied to clipboard",
    "scraping-website": "Scraping website - this may take a while.",
    "fetching-transcript": "Fetching transcript for YouTube video.",
    "updating-workspace": "Updating workspace...",
    "workspace-updated": "Workspace updated successfully.",
    "link-uploaded": "Link uploaded successfully",
    "password-reset": "Password reset successful",
    "invalid-reset": "Invalid reset token",
    "delete-option": "Thread could not be deleted!",
    "thread-deleted": "Thread deleted successfully!",
    "picture-uploaded": "Profile picture uploaded.",
    "profile-updated": "Profile updated.",
    "logs-cleared": "Event logs cleared successfully.",
    "preferences-updated": "System preferences updated successfully.",
    "user-created": "User created successfully.",
    "user-deleted": "User deleted from system.",
    "workspaces-saved": "Workspaces saved successfully!",
    "failed-workspaces": "Failed to save workspaces. Please try again.",
    "api-deleted": "API Key permanently deleted",
    "api-copied": "API Key copied to clipboard",
    "appname-updated": "Successfully updated custom app name.",
    "language-updated": "Successfully updated language.",
    "palette-updated": "Successfully updated palette.",
    "image-uploaded": "Image uploaded successfully.",
    "image-removed": "Image successfully removed.",
    "updated-welcome": "Successfully updated welcome messages.",
    "updated-footer": "Successfully updated footer icons.",
    "updated-paragraph": "Successfully updated custom paragraph text.",
    "updated-supportemail": "Successfully updated support email.",
    "stt-success": "Speech-to-text preferences saved successfully.",
    "tts-success": "Text-to-speech preferences saved successfully.",
    "failed-chats-export": "Failed to export chats.",
    "cleared-chats": "Cleared all chats.",
    "embed-deleted": "Embed deleted from system.",
    "snippet-copied": "Snippet copied to clipboard!",
    "embed-updated": "Embed updated successfully.",
    "embedding-saved": "Embedding preferences saved successfully.",
    "chunking-settings": "Text chunking strategy settings saved.",
    "llm-saved": "LLM preferences saved successfully.",
    "multiuser-enabled": "Multi-User mode enabled successfully.",
    "publicuser-enabled": "Public-User mode enabled successfully.",
    "publicuser-disabled": "Public-User mode disabled successfully.",
    "page-refresh": "Your page will refresh in a few seconds.",
    "transcription-saved": "Transcription preferences saved successfully.",
    "vector-saved": "Vector database preferences saved successfully.",
    "workspace-not-deleted": "Workspace could not be deleted!",
    "maximum-messages": "Maximum of 4 messages allowed.",
    "users-updated": "Users updated successfully.",
    "vectordb-not-reset": "Workspace vector database could not be reset!",
    "vectordb-reset": "Workspace vector database was reset!",
    "meta-data-update": "Site preferences updated!",
    "linked-workspaces-updated": "Linked workspaces updated successfully.",
  },

  "llm-selection-privacy": {
    openai: {
      description: [
        "Your chats will not be used for training",
        "Your prompts and document text used in response creation are visible to OpenAI",
      ],
    },
    azure: {
      description: [
        "Your chats will not be used for training",
        "Your text and embedding text are not visible to OpenAI or Microsoft",
      ],
    },
    anthropic: {
      description: [
        "Your chats will not be used for training",
        "Your prompts and document text used in response creation are visible to Anthropic",
      ],
    },
    gemini: {
      description: [
        "Your chats are de-identified and used in training",
        "Your prompts and document text used in response creation are visible to Google",
      ],
    },
    lmstudio: {
      description: [
        "Your model and chats are only accessible on the server running LMStudio",
      ],
    },
    localai: {
      description: [
        "Your model and chats are only accessible on the server running LocalAI",
      ],
    },
    ollama: {
      description: [
        "Your model and chats are only accessible on the machine running Ollama models",
      ],
    },
    native: {
      description: [
        "Your model and chats are only accessible on this instance",
      ],
    },
    togetherai: {
      description: [
        "Your chats will not be used for training",
        "Your prompts and document text used in response creation are visible to TogetherAI",
      ],
    },
    mistral: {
      description: [
        "Your prompts and document text used in response creation are visible to Mistral",
      ],
    },
    huggingface: {
      description: [
        "Your prompts and document text used in response are sent to your HuggingFace managed endpoint",
      ],
    },
    perplexity: {
      description: [
        "Your chats will not be used for training",
        "Your prompts and document text used in response creation are visible to Perplexity AI",
      ],
    },
    openrouter: {
      description: [
        "Your chats will not be used for training",
        "Your prompts and document text used in response creation are visible to OpenRouter",
      ],
    },
    groq: {
      description: [
        "Your chats will not be used for training",
        "Your prompts and document text used in response creation are visible to Groq",
      ],
    },
    koboldcpp: {
      description: [
        "Your model and chats are only accessible on the server running KoboldCPP",
      ],
    },
    textgenwebui: {
      description: [
        "Your model and chats are only accessible on the server running the Oobabooga Text Generation Web UI",
      ],
    },
    "generic-openai": {
      description: [
        "Data is shared according to the terms of service applicable with your generic endpoint provider.",
      ],
    },
    cohere: {
      description: [
        "Data is shared according to the terms of service of cohere.com and your localities privacy laws.",
      ],
    },
    litellm: {
      description: [
        "Your model and chats are only accessible on the server running LiteLLM",
      ],
    },
  },

  "vector-db-privacy": {
    chroma: {
      description: [
        "Your vectors and document text are stored on your Chroma instance",
        "Access to your instance is managed by you",
      ],
    },
    pinecone: {
      description: [
        "Your vectors and document text are stored on Pinecone's servers",
        "Access to your data is managed by Pinecone",
      ],
    },
    qdrant: {
      description: [
        "Your vectors and document text are stored on your Qdrant instance (cloud or self-hosted)",
      ],
    },
    weaviate: {
      description: [
        "Your vectors and document text are stored on your Weaviate instance (cloud or self-hosted)",
      ],
    },
    milvus: {
      description: [
        "Your vectors and document text are stored on your Milvus instance (cloud or self-hosted)",
      ],
    },
    zilliz: {
      description: [
        "Your vectors and document text are stored on your Zilliz cloud cluster.",
      ],
    },
    astra: {
      description: [
        "Your vectors and document text are stored on your cloud AstraDB database.",
      ],
    },
    lancedb: {
      description: [
        "Your vectors and document text are stored privately on this instance of the platform",
      ],
    },
  },

  "embedding-engine-privacy": {
    native: {
      description: [
        "Your document text is embedded privately on this instance of the platform",
      ],
    },
    openai: {
      description: [
        "Your document text is sent to OpenAI servers",
        "Your documents are not used for training",
      ],
    },
    azure: {
      description: [
        "Your document text is sent to your Microsoft Azure service",
        "Your documents are not used for training",
      ],
    },
    localai: {
      description: [
        "Your document text is embedded privately on the server running LocalAI",
      ],
    },
    ollama: {
      description: [
        "Your document text is embedded privately on the server running Ollama",
      ],
    },
    lmstudio: {
      description: [
        "Your document text is embedded privately on the server running LMStudio",
      ],
    },
    cohere: {
      description: [
        "Data is shared according to the terms of service of cohere.com and your localities privacy laws.",
      ],
    },
    voyageai: {
      description: [
        "Data sent to Voyage AI's servers is shared according to the terms of service of voyageai.com.",
      ],
    },
  },

  "prompt-validate": {
    edit: "Edit",
    response: "Response",
    prompt: "Prompt",
    regenerate: "Regenerate response",
    good: "Good response",
    bad: "Bad response",
    copy: "Copy",
    more: "More actions",
    fork: "Fork",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save & Submit",
  },

  citations: {
    show: "Show Citations",
    hide: "Hide Citations",
    chunk: "Citation Chunks",
    pdr: "Parent Document",
    "pdr-h": "Document Highlighting",
    referenced: "Referenced",
    times: "times.",
    citation: "Citation",
    match: "match",
    download:
      "This browser does not support PDFs. Please download the PDF to view it:",
    "download-btn": "Download PDF",
  },

  "document-drafting": {
    title: "Document Drafting",
    description: "Control your document drafting settings.",
    configuration: "Configuration",
    "drafting-model": "Drafting LLM",
    enabled: "Document Drafting is enabled",
    disabled: "Document Drafting is disabled",
    "enabled-toast": "Document Drafting enabled",
    "disabled-toast": "Document Drafting disabled",
    "desc-settings":
      "The Admin can change the document drafting settings for all users.",
    "drafting-llm": "Drafting LLM Preference",
    saving: "Saving...",
    save: "Save changes",
    "chat-settings": "Chat Settings",
    "drafting-chat-settings": "Document Drafting Chat Settings",
    "chat-settings-desc":
      "Control the behavior of the chat feature for document drafting.",
    "drafting-prompt": "Document Drafting system Prompt",
    "drafting-prompt-desc":
      "The system prompt that will be used in document drafting is different from the legal Q&A systemprompt. Document drafting system prompt defines the context and instructions for the AI to generate a response. You should to provide a carefully crafted prompt so the AI can generate a relevant and accurate response",
  },

  modale: {
    document: {
      title: "My Documents",
      document: "Documents",
      search: "Search for document",
      folder: "New Folder",
      name: "Name",
      empty: "No Documents",
      "move-workspace": "Move to Workspace",
      "doc-processor": "Document Processor Unavailable",
      "processor-offline":
        "We can't upload your files right now because the document processor is offline. Please try again later.",
      "drag-drop": "Click to upload or drag and drop",
      "supported-files":
        "supports text files, csv's, spreadsheets, audio files, and more!",
      "submit-link": "or submit a link",
      fetch: "Fetch website",
      fetching: "Fetching...",
      "file-desc":
        "These files will be uploaded to the document processor running on this instance. These files are not sent or shared with a third party.",
      cost: "*One time cost for embeddings",
      "save-embed": "Save and Embed",
      "failed-uploads": "Not able to upload the following files",
      "loading-message": "This may take a while for large documents",
    },
    connectors: {
      title: "Data Connectors",
      search: "Search data connectors",
      empty: "No data connectors found.",
    },
  },

  dataConnectors: {
    github: {
      name: "GitHub Repo",
      description:
        "Import an entire public or private Github repository in a single click.",
      url: "GitHub Repo URL",
      "collect-url": "Url of the GitHub repo you wish to collect.",
      "access-token": "Github Access Token",
      optional: "optional",
      "rate-limiting": "Access Token to prevent rate limiting.",
      "desc-picker":
        "Once complete, all files will be available for embedding into workspaces in the document picker.",
      branch: "Branch",
      "branch-desc": "Branch you wish to collect files from.",
      "branch-loading": "-- loading available branches --",
      "desc-start": "Without filling out the",
      "desc-token": "Github Access Token",
      "desc-connector": "this data connector will only be able to collect the",
      "desc-level": "top-level",
      "desc-end": "files of the repo due to GitHub's public API rate-limits.",
      "personal-token":
        "Get a free Personal Access Token with a GitHub account here.",
      without: "Without a",
      "personal-token-access": "Personal Access Token",
      "desc-api":
        ", the GitHub API may limit the number of files that can be collected due to rate limits. You can",
      "temp-token": "create a temporary Access Token",
      "avoid-issue": "to avoid this issue.",
      submit: "Submit",
      "collecting-files": "Collecting files...",
    },
    "youtube-transcript": {
      name: "YouTube Transcript",
      description:
        "Import the transcription of an entire YouTube video from a link.",
      url: "YouTube Video URL",
      "url-video": "URL of the YouTube video you wish to transcribe.",
      collect: "Collect transcript",
      collecting: "Collecting transcript...",
      "desc-end":
        "once complete, the transcription will be available for embedding into workspaces in the document picker.",
    },
    "website-depth": {
      name: "Bulk Link Scraper",
      description: "Scrape a website and its sub-links up to a certain depth.",
      url: "Website URL",
      "url-scrape": "URL of the website you want to scrape.",
      depth: "Depth",
      "child-links":
        "This is the number of child-links that the worker should follow from the origin URL.",
      "max-links": "Max Links",
      "links-scrape": "Maximum number of links to scrape.",
      scraping: "Scraping website...",
      submit: "Submit",
      "desc-scrap":
        "Once complete, all scraped pages will be available for embedding into workspaces in the document picker.",
    },
    confluence: {
      name: "Confluence",
      description: "Import an entire Confluence page in a single click.",
      url: "Confluence Page URL",
      "url-page": "URL of a page in the Confluence space.",
      username: "Confluence Username",
      "own-username": "Your Confluence username.",
      token: "Confluence Access Token",
      "desc-start":
        "You need to provide an access token for authentication. You can generate an access token",
      here: "here",
      access: "Access token for authentication.",
      collecting: "Collecting pages...",
      submit: "Submit",
      "desc-end":
        "Once complete, all pages will be available for embedding into workspaces.",
    },
  },

  module: {
    "legal-qa": "Legal Q&A",
    "document-drafting": "Document Drafting",
  },

  "fine-tune": {
    title: "You have enough data for a fine-tune!",
    link: "click to learn more",
    dismiss: "dismiss",
  },
  mobile: {
    disclaimer:
      "DISCLAIMER: For the best experience and full access to all features, please use a computer to access the app.",
  },
  onboarding: {
    welcome: "Welcome to",
    "get-started": "Get started",
    "llm-preference": {
      title: "LLM Preference",
      description:
        "ISTLLM can work with many LLM providers. This will be the service which handles chatting.",
      "LLM-search": "Search LLM providers",
    },
    "user-setup": {
      title: "User Setup",
      description: "Configure your user settings.",
      "sub-title": "How many people will be using your instance?",
      "single-user": "Just me",
      "multiple-user": "My team",
      "setup-password": "Would you like to set up a password?",
      "password-requirment": "Passwords must be at least 8 characters.",
      "save-password":
        "It's important to save this password because there is no recovery method.",
      "password-label": "Instance Password",
      username: "Admin account username",
      password: "Admin account password",
      "account-requirment":
        "Username must be at least 6 characters long and only contain lowercase letters, numbers, underscores, and hyphens with no spaces. Password must be at least 8 characters long.",
      "password-note":
        "By default, you will be the only admin. Once onboarding is completed you can create and invite others to be users or admins. Do not lose your password as only admins can reset passwords.",
    },
    "data-handling": {
      title: "Data Handling & Privacy",
      description:
        "We are committed to transparency and control when it comes to your personal data.",
      "llm-label": "LLM Selection",
      "embedding-label": "Embedding Preference",
      "database-lablel": "Vector Database",
      "reconfigure-option":
        "These settings can be reconfigured at any time in the settings.",
    },
    survey: {
      title: "Welcome to IST Legal LLM",
      description: "Help us make IST Legal LLM built for your needs. Optional.",
      email: "What's your email?",
      usage: "What will you use the platform for?",
      work: "For work",
      "personal-use": "For my personal use",
      other: "Other",
      comment: "Any comments for the team?",
      optional: "(Optional)",
      feedback: "Thank you for your feedback!",
    },
    button: {
      yes: "Yes",
      no: "No",
      "skip-survey": "Skip Survey",
    },
    placeholder: {
      "admin-password": "Your admin password",
      "admin-username": "Your admin username",
      "email-example": "you@gmail.com",
      comment:
        "If you have any questions or comments right now, you can leave them here and we will get back to you. You can also email richard.sahlberg@foytech.se",
    },
  },
  "default-settings": {
    title: "Default Settings for Legal Q&A",
    "default-desc": "Control the default behavior of workspaces for Legal Q&A",
    prompt: "Legal Q&A system Prompt",
    "prompt-desc":
      "The default prompt that will be used in Legal Q&A. Define the context and instructions for the AI to generate a response. You should to provide a carefully crafted prompt so the AI can generate a relevant and accurate response.",
    "toast-success": "Default system prompt updated",
    "toast-fail": "Failed to updated system prompt",
    snippets: {
      title: "Default Max Context Snippets",
      description:
        "This setting controls the default maximum amount of context snippets the will be sent to the LLM for per chat or query.",
      recommend: "Recommended: 10",
    },
  },
  "confirm-message": {
    "delete-doc":
      "Are you sure you want to delete these files and folders?\nThis will remove the files from the system and remove them from any existing workspaces automatically.\nThis action is not reversible.",
  },
  "pdr-settings": {
    title: "PDR Settings",
    description:
      "Control the dynamic token limit handling mechanisms for PDR algorithm",
    "desc-end":
      "You should only modify this setting if you understand how PDR algorithm works.",
    "pdr-token-limit": "PDR Token Limit",
    "pdr-token-limit-desc":
      "The maximum number of tokens that will be used for the PDR algorithm.",
    "input-prompt-token-limit": "Input Prompt Token Limit",
    "input-prompt-token-limit-desc":
      "The maximum number of tokens that will be used for the input prompt.",
    "response-token-limit": "Response Token Limit",
    "response-token-limit-desc":
      "The maximum number of tokens that will be used for the response.",
    "toast-success": "PDR settings updated",
    "toast-fail": "Failed to update PDR settings",
  },
};

export default TRANSLATIONS;
